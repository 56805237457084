<template>
 <v-card flat tile class="pa-0 fill-height">
   <template v-for="(visita_dia, i) in itemsVendedores">
      <v-slide-y-transition
        class="py-0"
        group
      >
        <visita-card
          :details="visita_dia"
          :fecha="dia.fecha"
          :key="'diavisita-'+visita_dia.id"
          :index="i"
        />
     </v-slide-y-transition>
    </template>
 </v-card>
</template>
<script>
import moment from 'moment'
import es from 'moment/locale/es'
moment.locale('es', es);

const sortItems = (items, sortBy, sortDesc) => {
  return items.sort((a,b) => {
      const now = moment().format('YYYY-MM-DD')
      let sortA = sortBy === 'co_cli' ? a.co_cli : 0
      let sortB = sortBy === 'co_cli' ? b.co_cli : 0

      if(sortBy === 'hora_inicio') {
        const horaDefault = sortDesc ? '00:00:00' : '24:00:00'
        sortA = moment(`${a.visita ? a.visita.fecha : now } ${a.visita ? a.visita.hora_inicio : horaDefault}`)
        sortB = moment(`${a.visita ? a.visita.fecha : now} ${b.visita ? b.visita.hora_inicio : horaDefault}`)
        return sortDesc ? sortB.diff(sortA) : sortA.diff(sortB)
      }

      if (sortDesc) {
        [sortA, sortB] = [sortB, sortA]
      }

      return sortA - sortB
  })
}

export default {
  name: 'DiaVisitas',
  components:{
    VisitaCard: () => import(
      /* webpackChunkName: "visita-card" */
      './VisitaCard.vue'
    ),
   },
  props:{
    dia: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
  },
  computed: {
    itemsVendedores () {
      let vendedores = this.dia.clientes.map(item => item)
      return vendedores
    },
    colorCard(){
      return this.index % 2 === 0 ? 'light-blue-color' : 'transparent'
    }
  }
}
</script>
