<template>
  <v-row class="my-2">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12" class="py-6">
      <v-row>
        <v-col cols="3" md="1" class="d-flex align-start pa-4">
          <div class="d-flex flex-column text-right">
            <span class="text-h6 font-weight-light blue-grey--text">{{dia.fecha | diaSemana}}</span>
            <span class="font-weight-bold primary--text" style="line-height: 1em !important"
              :class="{'text-h2':!$vuetify.breakpoint.xsOnly, 'text-h3': $vuetify.breakpoint.xsOnly}"
            >
              {{dia.fecha | diaMes}}
            </span>
            <span class="text-h6 font-weight-light blue-grey--text">{{dia.fecha | mes}}</span>
          </div>
        </v-col>
        <v-col cols="9" md="11" class="actions--style py-0">
          <v-row>
            <v-col cols="12" class="py-0">
              <visita-clientes :clientes="dia.clientes" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import es from 'moment/locale/es'
moment.locale('es', es);

export default {
  name: 'DiaVisitas',
  components:{
    VisitaClientes: () => import(
      /* webpackChunkName: "visita-clientes" */
      './VisitaClientesOld.vue'
    ),
   },
  props:{
    dia: {
      type: Object,
      default: () => ({}),
    }
  },
  filters:{
    diaMes(date){
      return moment(date).format("DD")
    },
    diaSemana(date){
      return moment(date).format("ddd")
    },
    mes(date){
      return moment(date).format("MMMM");
    }
  }
}
</script>