<template>
 <v-card flat color="light-blue-color" class="py-2 px-3 fill-height">
   <template v-for="(visita_dia, i) in itemsClients">
      <v-slide-y-transition
        class="py-0"
        group
      >
        <visita-clientes :cliente="visita_dia" :fecha="dia.fecha" :key="'diavisita-'+visita_dia.id" />
     </v-slide-y-transition>
    </template>
 </v-card>
</template>
<script>
import moment from 'moment'
import es from 'moment/locale/es'
import { gmapApi } from 'gmap-vue'
moment.locale('es', es);

const sortItems = (items, sortBy, sortDesc) => {
  return items.sort((a,b) => {
      const now = moment().format('YYYY-MM-DD')
      let sortA = sortBy === 'co_cli' ? a.co_cli : 0
      let sortB = sortBy === 'co_cli' ? b.co_cli : 0

      if(sortBy === 'hora_inicio') {
        const horaDefault = sortDesc ? '00:00:00' : '24:00:00'
        sortA = moment(`${a.visita ? a.visita.fecha : now } ${a.visita ? a.visita.hora_inicio : horaDefault}`)
        sortB = moment(`${a.visita ? a.visita.fecha : now} ${b.visita ? b.visita.hora_inicio : horaDefault}`)
        return sortDesc ? sortB.diff(sortA) : sortA.diff(sortB)
      }

      if (sortDesc) {
        [sortA, sortB] = [sortB, sortA]
      }

      return sortA - sortB
  })
}

export default {
  name: 'DiaVisitas',
  components:{
    VisitaClientes: () => import(
      /* webpackChunkName: "visita-clientes" */
      './VisitaClientes.vue'
    ),
   },
  props:{
    dia: {
      type: Object,
      default: () => ({}),
    },
    sort: {
      type: String,
      default: 'hora_inicio',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    RangoVisita (val) {
      this.$gmapApiPromiseLazy().then(() => {
        if(val.lat !== null && val.lng !== null && val.visita) {
          var visita = new this.googleApi.maps.LatLng(parseFloat(val.visita.lat.trim()), parseFloat(val.visita.lng.trim()));
          var cliente = new this.googleApi.maps.LatLng(parseFloat(val.lat.trim()), parseFloat(val.lng.trim()));

          return this.googleApi.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);
        }

        return -1
      })
    },
  },
  computed: {
    googleApi: gmapApi,
    itemsClients () {
      let clientes = this.dia.clientes.map(item => item)
      return sortItems(clientes, this.sort, this.sortDesc)
    },
  }
}
</script>
